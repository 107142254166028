<template>
<div>
  <el-card style="width: 40pc;margin-left: 30pc" v-show="formVisible">
    <el-form :model="formData" :label-position="labelPosition" label-width="200px">
      <el-form-item :label="$t('msg.account.contractOwner')" prop="contractOwner" required>
        <el-select v-model="formData.contractOwner" placeholder="L2 Owner" @change="onOwnerChange">
          <el-option label="Surpath" value="Surpath"></el-option>
          <el-option label="E2E" value="E2E"></el-option>
          <el-option label="Alpha" value="Alpha"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('msg.rate.hisTag')" required>
        <el-select v-model="formData.hisTag" @change="getParamData">
          <el-option v-for="item in hisTagList" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('msg.rate.pol')" required>
        <el-select v-model="formData.pol" multiple @change="polSelectionChange">
          <el-option v-for="item in pols" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('msg.rate.pod')" required>
        <el-select v-model="formData.pod" multiple @change="podSelectionChange">
          <el-option v-for="item in pods" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('msg.rate.payTerm')" required>
        <el-select v-model="formData.payTerm" >
          <el-option v-for="item in payTerms" :key="item" :label="item" :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" @click="onSubmit()">{{$t('msg.rate.getQuote')}}</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card v-show="tagShow">
    <el-button type="primary" @click="download('L2')">{{$t('msg.rate.l2CSVDownload')}}</el-button>
    <el-button type="primary" @click="download('L3')">{{$t('msg.rate.l3CSVDownload')}}</el-button>
    <el-button type="primary" @click="editShow()">{{$t('msg.rate.createQuote')}}</el-button>
    <el-divider></el-divider>
    <el-table ref="tableRef" :data="tableData" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" style="font-size: 12px" border >
      <el-table-column :label="$t('msg.rate.quotePayload')" align="center">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="pol" :label="$t('msg.rate.pol')" sortable align="center" ></el-table-column>
      <el-table-column prop="pod" :label="$t('msg.rate.pod')" sortable align="center" ></el-table-column>
      <el-table-column prop="ipi" :label="$t('msg.rate.ipi')" sortable align="center" ></el-table-column>
      <el-table-column prop="carrier" :label="$t('msg.rate.carrier')" sortable align="center" ></el-table-column>
      <el-table-column prop="remark" :label="$t('msg.rate.remark')" sortable align="center" ></el-table-column>
      <el-table-column prop="terminal" label="Terminal" sortable align="center" ></el-table-column>
      <el-table-column prop="lfd" label="LFD" sortable align="center" ></el-table-column>
      <el-table-column prop="c2" label="C2" sortable align="center" ></el-table-column>
      <el-table-column prop="transitDays" label="TranTime" sortable align="center" ></el-table-column>
      <el-table-column prop="c20GP" label="20GP" sortable align="center" >
        <template #default="scope">
          <el-input v-model="scope.row.remark" v-if="scope.$index===-1"></el-input>
          <span v-else-if="scope.row.c20GP===0">--</span>
          <span v-else>$ {{scope.row.c20GP}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="c40GP" label="40GP" sortable align="center" >
        <template #default="scope">
          <el-input v-model="scope.row.remark" v-if="scope.$index===-1"></el-input>
          <span v-else-if="scope.row.c40GP===0">--</span>
          <span v-else>$ {{scope.row.c40GP}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="c40HQ" label="40HQ" sortable align="center" >
        <template #default="scope">
          <el-input v-model="scope.row.remark" v-if="scope.$index===-1"></el-input>
          <span v-else-if="scope.row.c40HQ===0">--</span>
          <span v-else>$ {{scope.row.c40HQ}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="c45HQ" label="45HQ" sortable align="center" >
        <template #default="scope">
          <el-input v-model="scope.row.remark" v-if="scope.$index===-1"></el-input>
          <span v-else-if="scope.row.c45HQ===0">--</span>
          <span v-else>$ {{scope.row.c45HQ}}</span>
        </template>
      </el-table-column>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <span class="mySpan">{{$t('msg.rate.projectProfit')}}</span>
        </template>
      <el-table-column prop="pc20GP" sortable align="center" >
        <template #header>
          <span class="mySpan">20GP</span>
        </template>
        <template #default="scope">
          <span class="mySpan">$ {{scope.row.pc20GP}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pc40GP" sortable align="center" >
        <template #header>
          <span class="mySpan">40GP</span>
        </template>
        <template #default="scope">
          <span class="mySpan">$ {{scope.row.pc40GP}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pc40HQ" sortable align="center" >
        <template #header>
          <span class="mySpan">40HQ</span>
        </template>
        <template #default="scope">
          <span class="mySpan">$ {{scope.row.pc40HQ}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pc45HQ" sortable align="center" >
        <template #header>
          <span class="mySpan">45HQ</span>
        </template>
        <template #default="scope">
          <span class="mySpan">$ {{scope.row.pc45HQ}}</span>
        </template>
      </el-table-column>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-tag style="float: right;margin-bottom: 20px" size="large" round v-show="tagShow">L2 Tag {{l2Owner}} {{l2Tag}}</el-tag>
    <el-dialog title="Quote Basic Information" v-model="editFormVisible" v-on:close="resetForm()" width="30%" center draggable>
      <el-radio-group v-model="clientType" >
        <el-radio-button label="New Client">{{$t('msg.rate.newClient')}}</el-radio-button>
        <el-radio-button label="Old Client">{{$t('msg.rate.oldClient')}}</el-radio-button>
      </el-radio-group>
      <el-divider></el-divider>
      <el-form :model="editFormData" >
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName" style="width: 350px">
          <el-input v-model="editFormData.clientName" v-if="clientType==='New Client'"></el-input>
          <el-select v-model="editFormData.oldClient" placeholder="Select" style="width: 120px" v-else>
            <el-option v-for="item in customerList" :key="item.name" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner" required style="width: 420px">
          <el-select v-model="editFormData.l3Owner" placeholder="L3 Owner">
            <el-option label="Surpath" value="Surpath"></el-option>
            <el-option label="E2E" value="E2E"></el-option>
            <el-option label="Alpha" value="Alpha"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark" required style="width: 350px">
          <el-input v-model="editFormData.pageRemark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.startDate')" prop="startDate" required >
          <el-date-picker v-model="editFormData.startDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.endDate')" prop="endDate" required >
          <el-date-picker v-model="editFormData.endDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-on:click="excelQuoteDL()" style="float: left">Get Quote In EXCEL</el-button>
      <el-button type="primary" v-on:click="pdfDownload()" style="float: right">Get Quote In PDF</el-button>
    </el-dialog>
  </el-card>
</div>
</template>

<script>
import { onActivated, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import axios from 'axios'
import { csvDownload, pdfGenerate, excelGenerate } from '@/utils/download'

export default {
  name: 'OceanQuery',
  setup() {
    const formVisible = ref(true)
    const hisTagList = ref([])
    const pols = ref([])
    const pods = ref([])
    const payTerms = ref([])
    const polsMultipleSelection = ref([])
    const podsMultipleSelection = ref([])
    const labelPosition = ref('left')
    const customerList = ref([])
    const multipleSelection = ref([])
    const formData = ref({ contractOwner: 'Surpath', hisTag: 'Latest', pol: '', pod: '', payTerm: '' })
    const tableData = ref([])
    const l2Tag = ref('')
    const l2Owner = ref('')
    const tagShow = ref(false)
    const lowRateIndex = ref([])
    const editFormVisible = ref(false)
    const editFormData = ref({ clientName: '', oldClient: '', l3Owner: '', startDate: '', endDate: '', pageRemark: '' })
    const clientType = ref('New Client')
    const curUser = localStorage.getItem('curUser')
    const i18n = useI18n()
    const onOwnerChange = () => {
      formData.value.hisTag = 'Latest'
      getParamData()
    }
    const getParamData = () => {
      const param = 'contractOwner=' + formData.value.contractOwner + '&hisTag=' + formData.value.hisTag
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getFourList'
      axios.post(reqURL, param).then((response) => {
        hisTagList.value = response.data.clientList
        pols.value = response.data.polList
        pods.value = response.data.podList
        payTerms.value = response.data.payTermList
      }).catch((response) => {
        console.log(response)
      })
    }
    onActivated(getParamData)
    const onSubmit = () => {
      const param = 'contractOwner=' + formData.value.contractOwner + '&validTag=' + formData.value.hisTag + '&pol=' +
        JSON.stringify(polsMultipleSelection.value) + '&pod=' + JSON.stringify(podsMultipleSelection.value) + '&payTerm=' + formData.value.payTerm
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getQuoteForHisRC'
      axios.post(reqURL, param).then((response) => {
        tableData.value = response.data.ffcsopQuotes
        l2Tag.value = response.data.l2Tag
        l2Owner.value = response.data.l2Owner
        getLowComboRecord()
        adjustCol(tableData.value)
        tagShow.value = true
        formVisible.value = false
      })
    }
    const polSelectionChange = (val) => {
      polsMultipleSelection.value = val
    }
    const podSelectionChange = (val) => {
      podsMultipleSelection.value = val
    }
    const handleSelectionChange = (val) => {
      multipleSelection.value = val
    }
    const editShow = () => {
      if (multipleSelection.value.length === 0) {
        alert('Please Select at least 1 Base Rate as Quote')
        return false
      }
      const param = 'curName=' + localStorage.getItem('curUser')
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getUniqueClientName'
      axios.post(reqURL, param).then((response) => {
        customerList.value = response.data
        editFormVisible.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const download = (quoteType) => {
      const downloadName = 'OceanFreight-' + quoteType + '.csv'
      const param = 'baseRates=' + JSON.stringify(tableData.value) + '&quoteType=' + quoteType
      const url = '/ocean/l2CSVDl'
      csvDownload(url, param, downloadName)
    }
    const resetForm = () => {
      editFormData.value = { clientName: '', oldClient: 'New Client', l3Owner: '', startDate: '', endDate: '', pageRemark: '' }
    }
    const pdfDownload = () => {
      if (editFormData.value.oldClient === 'New Client' && editFormData.value.clientName === '') {
        alert('Please Input Name for New Client')
        return false
      }
      const client = clientType.value === 'New Client' ? editFormData.value.clientName : editFormData.value.oldClient
      const param = 'baseRates=' + JSON.stringify(multipleSelection.value) + '&clientName=' + client +
        '&l3Owner=' + editFormData.value.l3Owner + '&startDate=' + editFormData.value.startDate + '&endDate=' + editFormData.value.endDate +
        '&pageRemark=' + editFormData.value.pageRemark + '&curUser=' + curUser + '&l2Tag=' + l2Tag.value + '&l2Owner=' + l2Owner.value
      const downloadName = editFormData.value.l3Owner + ' to ' + client + '-Ocean Freight Quote' + '(' + editFormData.value.startDate + '-' + editFormData.value.endDate + ').pdf'
      const myUrl = '/ocean/l3quotePDF'
      editFormVisible.value = false
      pdfGenerate(myUrl, param, downloadName)
    }
    const excelQuoteDL = () => {
      const param = 'baseRates=' + JSON.stringify(multipleSelection.value) + '&startDate=' + editFormData.value.startDate + '&endDate=' + editFormData.value.endDate + '&curUser=' + curUser
      const downloadName = 'Ocean Freight Quote' + '(' + editFormData.value.startDate + '-' + editFormData.value.endDate + ').xlsx'
      const myUrl = '/ocean/createQuoteInExcelByTemplate'
      editFormVisible.value = false
      excelGenerate(myUrl, param, downloadName)
    }
    const getLowComboRecord = () => {
      const rates = tableData.value
      lowRateIndex.value.push(0)
      if (rates.length <= 1) return
      for (let i = 1; i < rates.length; i++) {
        if (rates[i].pol !== rates[i - 1].pol || rates[i].pod !== rates[i - 1].pod || rates[i].c2 !== rates[i - 1].c2) {
          lowRateIndex.value.push(i)
        }
      }
    }
    const tableRowClassName = (val) => {
      if (lowRateIndex.value.includes(val.rowIndex)) {
        return 'lowRate-row'
      } else {
        return ''
      }
    }
    function adjustCol(list) {
      list.forEach((record) => {
        const chars = record.remark.split('-')
        record.remark = chars[0]
        record.terminal = chars[1]
        record.lfd = chars[2]
        const tts = record.c2.split('-')
        record.c2 = tts[0]
        record.transitDays = tts[1]
      })
    }
    return { getParamData, onOwnerChange, formVisible, labelPosition, hisTagList, tableRowClassName, clientType, lowRateIndex, pols, pods, formData, payTerms, customerList, l2Tag, l2Owner, tagShow, getLowComboRecord, editShow, onSubmit, download, pdfDownload, excelQuoteDL, polSelectionChange, podSelectionChange, handleSelectionChange, resetForm, editFormVisible, editFormData, tableData, i18n }
  }
}
</script>
<style >
.mySpan{
  color: #0ea5e9;
}
.el-table .lowRate-row {
  background: #e0f6e0 !important;
}
</style>
